import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';

import { PortalModule } from '@angular/cdk/portal';
import { DateConversionsService, SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IframeComponent } from './shared/iframe/iframe.component';
import { CarouselSliderComponent } from './landing/carousel-slider/carousel-slider.component';
import { LandingComponent } from './landing/landing.component';
import { AuthConfigModule } from 'app/auth/oidc-config/auth-config.module';

import { AmplifyService } from './aws/amplify/amplify.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogModule } from '@angular/material/dialog';
import { SupersetComponent } from './superset/superset.component';

import { CustomerOnboardComponent } from './customer-onboard/customer-onboard.component';
import { AddEditCustomerConfigComponent } from './customer-onboard/add-edit-customer-config/add-edit-customer-config.component';
import { UserMenuDialogComponent } from './user-menu-dialog/user-menu-dialog.component';
import { XgsUmService } from './auth/xgs-service/xgs-um.service';
import { AuthGuard } from './auth/auth-guards/auth.guard';
import { InvalidLoginDialogComponent } from './invalid-login-dialog/invalid-login-dialog.component';
import { NgIdleModule } from '@ng-idle/core';
import { IdleLogoutDialogComponent } from './idle-logout-dialog/idle-logout-dialog.component';
import { S2sConnectionMgmtComponent } from './s2s-connection-mgmt/s2s-connection-mgmt.component';
import { S2sAddLoginDialogComponent } from './s2s-connection-mgmt/s2s-add-login-dialog/s2s-add-login-dialog.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { AuditTrailDialogComponent } from './user-management/audit-trail/audit-trail-dialog/audit-trail-dialog.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UserAuditService } from './user-management/audit-trail/user-audit-trail-service/user-audit.service';
import { UtilsModule } from '@gravity-angular/utils';
import { UsageModule } from './usage/usage.module';
import { UsageService } from './usage/usage-service/usage-service';
import { UserFunctionsService } from './user-management/user/user-functions-service/user-functions.service';
import { XgsGuardGuard } from './auth/auth-guards/xgs-guard.guard';

import { PreviousRouteService } from '@common/previous-route/previous-route.service';
import { OidcStorageService } from './auth/oidc-config/oidc-storage.service';
import { CustomerService } from './shared/services/customer-service/customer.service';
import { CustomTranslatorService } from './shared/services/custom-translator/custom-translator.service';
import { FeatureFlagsService } from './shared/services/feature-flags/feature-flags.service';
import { UserPreferencesService } from './shared/services/user-preferences/user-preferences.service';

/**
 * The main module of the application.
 */
@NgModule({
  declarations: [
    AppComponent,
    IframeComponent,
    LandingComponent,
    CarouselSliderComponent,
    SupersetComponent,
    CarouselSliderComponent,
    CustomerOnboardComponent,
    AuditTrailDialogComponent,
    AddEditCustomerConfigComponent,
    UserMenuDialogComponent,
    CarouselSliderComponent,
    InvalidLoginDialogComponent,
    IdleLogoutDialogComponent,
    S2sConnectionMgmtComponent,
    S2sAddLoginDialogComponent,
    ConfirmationDialogComponent,
    AuthorizeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    NgIdleModule.forRoot(),
    PortalModule,
    HttpClientModule,
    SharedModule,
    UtilsModule,
    MatDialogModule,
    LazyLoadImageModule,
    UsageModule,
    AuthConfigModule
  ],
  exports: [],
  providers: [
    XgsUmService,
    DateConversionsService,
    PreviousRouteService,
    AmplifyService,
    CustomerService,
    AuthGuard,
    XgsGuardGuard,
    UserAuditService,
    UsageService,
    UserFunctionsService,
    OidcStorageService,
    CustomTranslatorService,
    CookieService,
    FeatureFlagsService,
    UserPreferencesService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
