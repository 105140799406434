/* eslint-disable dot-notation */
import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subscription, from, lastValueFrom, of } from 'rxjs';
import { XgsUmService } from '../xgs-service/xgs-um.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { SupersetService } from 'app/superset/superset-service/superset.service';
import { concatMap, first } from 'rxjs/operators';
import {
  OidcConfigIds,
  UtilityOptionValue,
  UDLUserData,
  XGSRole
} from '@common/models/user-management.model';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { UmService } from '../um-service/um.service';
import { DatadogService } from 'app/shared/services/datadog-services/datadog.service';
import { StateService } from 'app/shared/services/state-services/state.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFunctionsService } from 'app/user-management/user/user-functions-service/user-functions.service';

/**
 * Route Guard to check if user has access to route via permissions and/or customer level access
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  utilityValue: UtilityOptionValue;
  subscription: Subscription;

  private userData: UDLUserData;
  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerService,
    private readonly cookieService: CookieService,
    private readonly umService: UmService,
    private readonly xgsUmService: XgsUmService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly supersetService: SupersetService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService
  ) {
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        this.utilityValue = utilityValue;
      }
    );
  }

  /**
   * Checks if user has approriate permissions for the route
   * selected for the customer selected from dropdown
   * @param route - The route snapshot containing the route configuration and query parameters.
   * @returns boolean/UrlTree
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const referrer = this.cookieService.get('referrer');
    if (referrer === OidcConfigIds.GOAIGUA) {
      return true;
    }

    this.userData = this.umService.getUserData();
    try {
      await this.getRoles();
      if (this.userData?.xgsPermissions?.length === 0) {
        this.oidcSecurityService.authorize(
          this.umService.userIdp ?? OidcConfigIds.XGS
        );
        return false;
      }

      if (route.data.permission.includes('Superset')) {
        return await this.supersetActivate();
      }

      const userCustomerPermissions =
        this.userFunctionsService.customersWithPermission(
          this.userData,
          route.data.permission
        );

      if (userCustomerPermissions.length === 0) {
        return this.router.createUrlTree(['/landing']);
      }

      if (!userCustomerPermissions.includes('AllCustomers')) {
        this.customerService.disableCustomersWithoutPermissions(
          userCustomerPermissions
        );
      }

      if (!route.data.access) {
        return true;
      }

      if (this.utilityValue.access?.includes(route.data.access)) {
        this.customerService.disableCustomersWithNoAccess(route.data.access);
        return true;
      }

      const correctCustomer = this.customerService.getCustomerWithAccess(
        route.data.access
      );
      if (correctCustomer) {
        this.customerService.onUtilityChanged(correctCustomer.value);
        this.customerService.disableCustomersWithNoAccess(route.data.access);
        return true;
      }

      this.customerService.enableCustomersAccess();
      return this.router.createUrlTree(['/landing']);
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error validating access to ${route.routeConfig.path}`
      });

      this.oidcSecurityService.authorize(
        this.umService.userIdp || OidcConfigIds.XGS
      );
    }
  }

  /**
   * Helper function to get users XGS roles
   * @returns list of user's XGS roles
   */
  async getRoles(): Promise<void> {
    if (
      this.userData?.xgsPermissions?.length > 0 &&
      Object.keys(this.userData?.xgsRoles ?? {}).length > 0
    ) {
      return;
    }

    if (this.userData?.id) {
      await this.xgsUmService.setUser(this.userData);
    }
  }

  /**
   * Checks if user has valid superset token
   * @returns true/Url Tree to landing or login
   */
  async supersetActivate(): Promise<boolean | UrlTree> {
    try {
      await this.supersetService.checkSupersetAuth();

      if (
        this.cookieService.check(
          `${environment.environmentName}_superset_token`
        )
      ) {
        return true;
      }

      return this.router.createUrlTree(['/landing']);
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred accessing Superset...'
      });
      this.oidcSecurityService.authorize(
        this.umService.userIdp || OidcConfigIds.XGS
      );
    }
  }
}

/**
 * CanComponentDeactivate & UnsavedChangesGuard
 * used to monitor if user leaves page before saving inputs
 * ie creation/edits
 */
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Deactivate Unsaved Changes Guard
 */
@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard {
  constructor(
    private readonly stateService: StateService,
    private readonly dialog: MatDialog
  ) {}

  /**
   * Following deactivation of route checks if user has unsaved changes
   * @param component - component to deactivate
   * @param currentRoute
   * @param currentState
   * @param nextState
   * @returns boolean
   */
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const state = this.stateService.getState();
    this.stateService.clearState();
    if (state?.[nextState.url]?.crossTabChange) {
      // Close any open dialogs
      this.dialog.closeAll();

      return true;
    }

    return component.canDeactivate ? component.canDeactivate() : true;
  }
}

/**
 * Route guard to reenable customers that may be disabled following leaving a customer restricted route
 */
@Injectable({
  providedIn: 'root'
})
export class DeactivateRestrictedRoute {
  constructor(private readonly customerService: CustomerService) {}
  /**
   * Reenables customers that may have been disabled following leaving a customer restricted route
   * @param component - component to be deactivated/user leaving
   * @param currentRoute - current route being accessed
   * @returns Observable<boolean> | Promise<boolean> | boolean
   */
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.customerService.enableCustomersAccess();

    return true;
  }
}

/**
 * Route guard allows for asynchronous
 * Auth Guards to execute synchronously
 */
@Injectable({
  providedIn: 'root'
})
export class SyncGuardHelper {
  constructor(private readonly injector: Injector) {}

  /**
   * SyncGuardHelper allows for asynchronous
   * Auth Guards to execute synchronously
   * @param route - The route snapshot containing the route configuration and query parameters.
   * @returns An observable that emits a boolean or UrlTree value.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return from(route.data.syncGuards).pipe(
      concatMap(value => {
        const guard = this.injector.get(value);
        const result = guard.canActivate(route);
        if (result instanceof Observable) {
          return result;
        } else if (result instanceof Promise) {
          return from(result);
        } else {
          return of(result);
        }
      }),
      first(x => {
        return x === false || x instanceof UrlTree;
      }, true)
    );
  }
}
