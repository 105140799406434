<div
  *ngIf="customTranslatorService.userLocaleData"
  class="xm-app"
  style="overflow: none"
>
  <div layout="column" id="overview" style="margin-left: 3%; margin-top: 1%">
    <div flex>
      <div id="header">
        <h1
          style="font-size: 38px"
          i18n="@@udl-usage_header_title"
          [textContent]="'udl-usage_header_title' | translate"
        >
          Usage
        </h1>
      </div>
    </div>

    <mat-card id="usageCard">
      <div class="form-group" style="pointer-events: none">
        <div style="width: 200px; display: inline">
          <h3
            for="customer_id"
            style="font-weight: bold; padding-left: 20px"
            i18n="@@udl-usage_header_usageCard"
            [textContent]="'udl-usage_header_usageCard' | translate"
          >
            Data Usage For Current Month
          </h3>
        </div>
        <h2>
          <input
            matInput
            id="customer_id"
            [ngClass]="dataLevel"
            required
            [value]="totalDataUsage"
          />
        </h2>
      </div>
      <br />
    </mat-card>

    <form [formGroup]="form">
      <mat-card id="inputCard">
        <table width="93%">
          <tr>
            <!-- Storage Column -->

            <td class="input" style="margin-left: 1%">
              <mat-form-field appearance="fill">
                <mat-label
                  i18n="@@udl-usage_usage_label_input_1"
                  [textContent]="'udl-usage_usage_label_input_1' | translate"
                  >Select start date</mat-label
                >
                <input
                  formControlName="startDate"
                  matInput
                  [max]="date"
                  placeholder="2022/05/01"
                  [matDatepicker]="picker"
                  (dateChange)="startDateChange()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </td>

            <td class="input">
              <mat-form-field appearance="fill">
                <mat-label
                  i18n="@@udl-usage_usage_label_input_2"
                  [textContent]="'udl-usage_usage_label_input_2' | translate"
                  >Select end date</mat-label
                >
                <input
                  formControlName="endDate"
                  matInput
                  [min]="sDate"
                  [max]="date"
                  placeholder="2022/05/02"
                  [matDatepicker]="picker2"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </td>

            <td class="input" style="float: right">
              <xg-button
                style="height: 100%; margin-top: 12.5%"
                icon="download"
                [text]="'udl-usage_xg-button-submit' | translate"
                buttonColor="primary"
                buttonType="flat"
                [disabled]="!validate"
                (buttonClick)="validate && updateTables()"
              ></xg-button>
            </td>
          </tr>
        </table>
      </mat-card>
    </form>
    <div id="usageGraph">
      <mat-spinner
        *ngIf="loadingGraphData == true"
        style="margin-left: 45%; margin-top: 5%"
      ></mat-spinner>
      <plotly-plot
        *ngIf="data_loaded == true"
        [data]="graph.data"
        [layout]="graph.layout"
        [style]="graph.style"
      ></plotly-plot>
    </div>

    <mat-card
      id="outputCard"
      class="outputChart"
      style="margin-top: 2%; margin-bottom: 7%"
    >
      <xg-tabs #regularTabs>
        <mat-tab [label]="labelQueries">
          <app-usage-queryhistory></app-usage-queryhistory>
        </mat-tab>

        <mat-tab [label]="labelTable">
          <app-usage-tablestorage></app-usage-tablestorage>
        </mat-tab>
      </xg-tabs>
    </mat-card>
  </div>
</div>
