import { NgModule } from '@angular/core';
import {
  mapToCanActivate,
  mapToCanDeactivate,
  mapToCanMatch,
  RouterModule,
  Routes
} from '@angular/router';
import { SupersetComponent } from './superset/superset.component';
import { LandingComponent } from './landing/landing.component';
import { CustomerOnboardComponent } from './customer-onboard/customer-onboard.component';
import { UsageComponent } from './usage/usage.component';
import {
  AuthGuard,
  DeactivateRestrictedRoute,
  SyncGuardHelper
} from './auth/auth-guards/auth.guard';
import { AuthorizeComponent } from './authorize/authorize.component';
import {
  CanDeactivateGuard,
  XgsGuardGuard
} from './auth/auth-guards/xgs-guard.guard';
import { ExdlPermissions } from '@common/models/user-management.model';
import { FeatureFlag } from './shared/services/feature-flags/feature-flags.service';

const routes: Routes = [
  {
    path: 'landing',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([XgsGuardGuard])
  },
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'home', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'authorize', component: AuthorizeComponent, pathMatch: 'full' },
  {
    path: 'superset',
    component: SupersetComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: 'Superset'
    }
  },
  {
    path: 'customer-onboard',
    component: CustomerOnboardComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.CUSTOMER_ONBOARDING
    }
  },
  {
    path: 'usage-screen',
    component: UsageComponent,
    pathMatch: 'full',
    canActivate: mapToCanActivate([SyncGuardHelper]),
    canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
    data: {
      syncGuards: [XgsGuardGuard, AuthGuard],
      permission: ExdlPermissions.RESOURCE_USAGE
    }
  },
  {
    path: 'user',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    /**
     * Lazy load the UserModule
     * @returns UserModule
     */
    loadChildren: async () => {
      const m = await import('./user-management/user/user.module');

      return m.UserModule;
    }
  },
  {
    path: 'role',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    /**
     * Lazy load the RoleModule
     * @returns RoleModule
     */
    loadChildren: async () => {
      const m = await import('./user-management/role/role.module');

      return m.RoleModule;
    }
  },
  {
    path: 'scheduler',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([
      CanDeactivateGuard,
      DeactivateRestrictedRoute
    ]),
    /**
     * Lazy loads the Scheduler module.
     * @returns - The Scheduler module.
     */
    loadChildren: async () => {
      const m = await import('./scheduler/scheduler.module');

      return m.SchedulerModule;
    }
  },
  {
    path: 'data-import',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
    data: {
      access: FeatureFlag.DATA_IMPORT
    },
    /**
     * Lazy loads the Data Import module.
     * @returns - The Data Import module.
     */
    loadChildren: async () => {
      const m = await import('./data-import/data-import.module');

      return m.DataImportModule;
    }
  },
  {
    path: 'health-Dashboard',
    canMatch: mapToCanMatch([XgsGuardGuard]),
    canDeactivate: mapToCanDeactivate([DeactivateRestrictedRoute]),
    /**
     * Lazy loads the Health Monitor module.
     * @returns - The Health Monitor module.
     */
    loadChildren: async () => {
      const m = await import('./health-monitor/health-monitor.module');

      return m.HealthMonitorModule;
    }
  },
  {
    path: '**',
    redirectTo: 'landing'
  }
];

/**
 * App Routing Module
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
