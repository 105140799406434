<!-- ***************************************************************************************** -->
<!-- This is the customer auto-complete field. User must select a customer before doing anything else on the screen. -->
<!-- ***************************************************************************************** -->
<div content *ngIf="isCustomerListReady">
  <mat-form-field class="form-field-autocomplete">
    <input
      type="text"
      placeholder="Select a customer"
      matInput
      [formControl]="myControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        (onSelectionChange)="autocompleteSelectionChanged(option)"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<!-- ***************************************************************************************** -->
<!-- Section 1 of the on-boarding process: An automated check to ensure the SA and RNI cust IDs are the same -->
<!-- ***************************************************************************************** -->
<div content style="margin-left: 30px">
  <h4>Config Status</h4>
  <div *ngIf="selectedCustomer" [hidden]="newUdlCustomer">
    <div>
      <div class="config-status-label">
        <label for="update_status"> Config Status </label>
      </div>
      <input
        [disabled]="true"
        matInput
        class="status-input"
        id="update_status"
        required
        [ngStyle]="
          selectedCustomer.update_status == 'succeeded'
            ? { color: 'green' }
            : selectedCustomer.update_status == 'update in progress'
            ? { color: 'orange' }
            : { color: 'red' }
        "
        [value]="selectedCustomer.update_status"
      />
    </div>
    <br />
    <div>
      <div class="config-status-label">
        <label for="last_modified_date"> Last Modified Date </label>
      </div>
      <input
        [disabled]="true"
        matInput
        class="modified-input"
        id="last_modified_date"
        required
        [value]="selectedCustomer.last_modified_date"
      />
    </div>
    <br />
    <div>
      <div class="config-status-label">
        <label for="last_modified_by"> Last Modified By </label>
      </div>
      <input
        [disabled]="true"
        matInput
        class="modified-input"
        id="last_modified_by"
        required
        [value]="selectedCustomer.last_modified_by"
      />
    </div>
  </div>

  <div [hidden]="!newUdlCustomer">
    <div style="display: inline-block">
      <label for="update_status">
        New Customer. Config status not available.
      </label>
    </div>
  </div>
</div>
<div content></div>

<!-- ***************************************************************************************** -->
<!-- Section 2 of the on-boarding process: The deployment details table should be reviewed to -->
<!-- ***************************************************************************************** -->
<div content style="margin-left: 30px">
  <h4>Deployment Details</h4>
  <table
    mat-table
    [dataSource]="dataSourceDeploymentDetails"
    class="mat-elevation-z8"
  >
    <ng-container *ngFor="let def of tableDefDeploymentDetails">
      <ng-container [matColumnDef]="def.key">
        <th mat-header-cell *matHeaderCellDef>{{ def.header }}</th>
        <td [ngClass]="def.className" mat-cell *matCellDef="let element">
          {{ element[def.key] }}
        </td>
      </ng-container>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumnsDeploymentDetails"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsDeploymentDetails"
    ></tr>
  </table>
</div>

<!-- ***************************************************************************************** -->
<!-- Section 3 of the on-boarding process: Complete the form -->
<!-- ***************************************************************************************** -->
<div style="margin-left: 30px">
  <h4 *ngIf="form">XDL Customer Config Form</h4>
  <div>
    <div [formGroup]="form" *ngIf="form" class="formBox">
      <p [hidden]="newUdlCustomer" class="edit-text">
        You are editing an existing customer in XDL
      </p>
      <div class="formContents">
        <h4>Customer Info</h4>
        <div formGroupName="alternate_customer_ids" class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label">Customer ID - SA</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="sa"
                class="customer-input customer-ID"
              />
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">Customer ID - RNI</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="rni"
                class="customer-input customer-ID"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label">Customer ID - XDL</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="customer_id"
                class="customer-input customer-ID"
              />
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">Cluster ID</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="cluster_nickname"
                class="customer-input"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label">Business Name</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="business_name"
                class="customer-input"
              />
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">Database Schema Name</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="db_schema_name"
                class="customer-input"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label"
              >Years to Keep Data in Redshift</label
            >
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="years_to_keep_in_redshift"
                class="customer-input"
              />
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">Data Archive Description</label>
            <mat-form-field appearance="outline" class="form-field-input">
              <input
                matInput
                formControlName="data_archive_description"
                class="customer-input"
              />
            </mat-form-field>
          </div>
        </div>
        <h4>XDL Enabled Data</h4>
        <div class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label">Water</label>
            <mat-form-field appearance="outline" class="form-field-select">
              <mat-select
                formControlName="is_enabled_water"
                placeholder="Make a selection..."
              >
                <mat-option [value]="false">False</mat-option>
                <mat-option [value]="true">True</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">Gas</label>
            <mat-form-field appearance="outline" class="form-field-select">
              <mat-select
                formControlName="is_enabled_gas"
                placeholder="Make a selection..."
              >
                <mat-option [value]="false">False</mat-option>
                <mat-option [value]="true">True</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-field-group">
          <div class="form-field-container">
            <label class="form-field-label">Electric</label>
            <mat-form-field appearance="outline" class="form-field-select">
              <mat-select
                formControlName="is_enabled_electric"
                placeholder="Make a selection..."
              >
                <mat-option [value]="false">False</mat-option>
                <mat-option [value]="true">True</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field-container">
            <label class="form-field-label">RNI</label>
            <mat-form-field appearance="outline" class="form-field-select">
              <mat-select
                formControlName="is_enabled_rni_data"
                placeholder="Make a selection..."
              >
                <mat-option [value]="false">False</mat-option>
                <mat-option [value]="true">True</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <h4>Additional XDL Access</h4>
        <div class="form-field-container-access">
          <div>
            <mat-checkbox formControlName="requires_s2s_vpn">
              <label class="access-label">S2S VPN Access</label>
            </mat-checkbox>
          </div>
          <div formGroupName="access">
            <div>
              <mat-checkbox [formControlName]="FeatureFlag.DATA_IMPORT">
                <label class="access-label">Data Import Access</label>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="submit-container">
          <xg-button
            class="submit-button"
            buttonType="raised"
            buttonColor="primary"
            (buttonClick)="onSubmit()"
            [disabled]="!form.get('customer_id').value || disableSubmit"
            [text]="'Submit'"
          >
          </xg-button>
        </div>
      </div>
    </div>
  </div>
</div>
<br /><br />

<div *ngIf="!newUdlCustomer && s2sVpnRequired">
  <span layout="row">
    <hr flex />
    <h3>Manage S2S Logins</h3>
    <hr flex />
  </span>
  <app-s2s-connection-mgmt
    *ngIf="selectedCustomer"
    [customerId]="selectedCustomer.customer_id"
    [cluster]="selectedCustomer.cluster_nickname"
    (loginsChangeEvent)="s2sLoginsChanged($event)"
  ></app-s2s-connection-mgmt>
</div>

<!-- ***************************************************************************************** -->
<!-- There are two tables below, they are not critical to on-boarding, but might be useful to the user in understanding some things -->
<!-- ***************************************************************************************** -->
<br /><br />
<span layout="row">
  <hr flex />
  <h3>Supporting reference info below (Not part of configuration form)</h3>
  <hr flex />
</span>

<div content>
  <!-- This table shows a lsit of XDL Redshift clusters -->
  <!-- and includes details such as the # of customers on the cluster and a description of what the cluster is intended for (single customer? many?) -->
  <h5>Redshift Clusters</h5>
  <table
    mat-table
    [dataSource]="dataSourceClusterMatTable"
    class="mat-elevation-z8"
  >
    <ng-container *ngFor="let def of tableDefCluster">
      <ng-container [matColumnDef]="def.key">
        <th mat-header-cell *matHeaderCellDef>{{ def.header }}</th>
        <td [ngClass]="def.className" mat-cell *matCellDef="let element">
          {{ element[def.key] }}
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsCluster"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsCluster"></tr>
  </table>

  <!-- This table shows the history of changes made to the config table (i.e. customer config for XDL) -->
  <!-- Columns in this table are sortable, so you can sort by customer id or by timestamp to find recent changes -->
  <h5>XDL Config Change History</h5>
  <table
    mat-table
    [dataSource]="dataSourceConfigHistoryMatTable"
    class="mat-elevation-z8"
    matSort
  >
    <ng-container *ngFor="let def of tableDefConfigHistory">
      <ng-container [matColumnDef]="def.key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ def.header }}
        </th>
        <td [ngClass]="def.className" mat-cell *matCellDef="let element">
          {{ element[def.key] }}
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsConfigHistory"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsConfigHistory"
    ></tr>
  </table>
</div>
