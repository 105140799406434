import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@env/environment';

/**
 * Service for interacting with Datadog RUM.
 */
@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  session: boolean;

  constructor() {}

  /**
   * Initialize DD RUM, set user, and start session recording
   * @param userId - The user ID to be set in the Datadog RUM session.
   */
  dataDogSet(userId: string): void {
    if (!this.session) {
      datadogRum.init({
        applicationId: environment.dataDogAppID,
        clientToken: environment.dataDogClientToken,
        site: 'datadoghq.com',
        service: 'utility-data-lake',
        env: environment.dataDogEnv,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      datadogRum.setUser({
        id: userId
      });

      datadogRum.startSessionReplayRecording();
      this.session = true;
    }
  }

  /**
   * Ends the Datadog RUM session and stops session replay recording.
   * Sets the session flag to false.
   */
  dataDogEnd(): void {
    if (this.session) {
      datadogRum.stopSessionReplayRecording();
      datadogRum.stopSession();
      this.session = false;
    }
  }

  /**
   * Sets the application version in the global context for Datadog RUM.
   * @param version - The version of the application to be set.
   */
  setAppVersion(version: string): void {
    datadogRum.setGlobalContext({
      appVersion: version
    });
  }

  /**
   * Tracks an error using Datadog RUM if a session is active.
   * Logs the error to the console in non-production environments if no session is active.
   * @param error - The error to be tracked.
   * @param context - Additional context information for the error.
   */
  errorTracking(error: Error, context: DatadogErrorContext): void {
    if (this.session) {
      datadogRum.addError(error, context);
    }

    if (!environment.production) {
      console.error(error);
      console.error(JSON.stringify(context));
    }
  }
}

export interface DatadogErrorContext {
  message: string;
  severity?: string;
  sessionId?: string;
  feature?: string;
}
